<template>
  <div>
    <div class="w-100 multiFilter">
      <div class="d-flex align-items-center justify-content-between position-relative">
        <input style="border: 0.66px solid #8A8A8A;
    padding: 6px 12px 6px 12px !important;
    border-radius: 8px;
    outline: none;
"
          ref="searchInput" class="filterSearch w-100" type="text" :id="'autocomplete' + id"
          placeholder="Please enter full address" :value="inputValue">
        <span v-if="isCheckingAddress" style="color:#ffa201;position: absolute;
    color: rgb(255, 162, 1);
    right: 10px;
    top: 2px;">
          <b-spinner style="width: 1rem;
    height: 1rem;"></b-spinner>
        </span>

      </div>
    </div>
    <i v-if="errorMessage" class="px-2 text-danger">{{ errorMessage }}</i>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";
let isGoogleMapsApiLoaded = false;
let googleMapsApiLoadPromise = null;
import { isEqual } from 'lodash';
export default {
  watch: {
    value(value) {

      this.inputValue = value ? value + '' : ''
    }
  },
  props: {
    destination: Object,
    pro: Object,
    id: String,
    value: String,
  },


  computed: {

    ...mapGetters(["getEnvConst"]),
  },
  data() {
    return {
      inputValue: '',
      isCheckingAddress: false,
      loadedResources: [],

      resource:
      {
        type: "script",
        src: ``,
      },
      autocomplete: null,
      selectedAddress: null,
      errorMessage: '',
    };
  },

  mounted() {


    let googleAPIKey = this.getEnvConst?.GOOGLE_MAP_API;
    if (!isGoogleMapsApiLoaded) {
      if (!googleMapsApiLoadPromise) {
        this.resource.src = `https://maps.googleapis.com/maps/api/js?key=${googleAPIKey}&libraries=places`
        googleMapsApiLoadPromise = this.loadResource(this.resource).then(() => {
          isGoogleMapsApiLoaded = true;
          this.initializeAutocomplete();
        }).catch((error) => {
          console.error("Resource loading failed:", error);
        });
      } else {
        googleMapsApiLoadPromise.then(() => {
          this.initializeAutocomplete();
        });
      }
    } else {
      this.initializeAutocomplete();
    }
  },

  beforeDestroy() {
    // this.removeScripts();
    this.removeResources();
  },

  methods: {
    compareDestination(address) {
      return isEqual(this.destination, address);
    },
    async ValidateAddress() {
      this.isCheckingAddress = true;
      try {
        const payload = { ...this.selectedAddress, api_token: this.$route.params.id }
        const response = await axios.post('/api/quiz/d4w-validate_address', payload)
        if (response.status == 200 && response.data[0] && response.data[0].status == "verified") {

          this.$emit('change', this.selectedAddress);
        }
        else {
          this.selectedAddress = null;
          this.$emit('change', null)
          this.errorMessage = 'Selected address is not verified.';
        }
        this.isCheckingAddress = false;
      }
      catch (error) {
        console.log(error)
      }
    },
    loadResource(resource) {
      return new Promise((resolve, reject) => {
        let element;
        if (resource.type === "script") {
          element = document.createElement("script");
          element.src = resource.src;
        }
        element.onload = () => {
          this.loadedResources.push(element);
          resolve(element);
        };
        element.onerror = () =>
          reject(
            new Error(
              `Resource load error for ${resource.src || resource.href}`
            )
          );

        document.head.appendChild(element);
      });
    },
    removeResources() {
      this.loadedResources.forEach((element) => {
        if (document.head.contains(element)) {
          document.head.removeChild(element);
        }
      });
      this.loadedResources = [];
    },
    initializeAutocomplete() {
      const input = document.getElementById('autocomplete' + this.id);
      // Initialize Google Places Autocomplete
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(input, {
        types: ['geocode'],
        componentRestrictions: { country: "us" },
      });
      // Specify the fields to return
      this.autocomplete.setFields(['address_component']);
      // Add a listener to handle the selection
      this.autocomplete.addListener('place_changed', this.fillInAddress);
    },
    fillInAddress() {
      const place = this.autocomplete.getPlace();
      const addressComponents = place.address_components;

      const address = {
        address_line1: '',
        city: '',
        stateCode: '',
        postalCode: '',
        countryCode: '',
      };

      addressComponents.forEach(component => {
        const types = component.types;

        if (types.includes('street_number')) {
          address.address_line1 += component.long_name + ' ';
        }
        if (types.includes('route')) {
          address.address_line1 += component.long_name;
        }
        if (types.includes('locality') || types.includes('sublocality') || types.includes('neighborhood')) {
          address.city = component.long_name;
        }
        if (types.includes('administrative_area_level_1')) {
          address.stateCode = component.short_name;
        }
        if (types.includes('postal_code')) {
          address.postalCode = component.long_name;
        }
        if (types.includes('country')) {
          address.countryCode = component.short_name;
        }
      });

      // Validate if all fields are filled
      if (this.compareDestination(address)) {
        this.selectedAddress = null;
        this.$emit('change', null)
        this.errorMessage = 'Origin and Destination date cannot be same.';
      }
      else if (address.address_line1.trim() && address.city && address.stateCode && address.postalCode && address.countryCode) {
        this.selectedAddress = address;
        const { address_line1, city, state, country } = this.selectedAddress ? { ...this.selectedAddress, country: 'USA' } : { address_line1: '', city: '', state: '', country: '' }
        this.inputValue = `${address_line1 || ''}, ${city ? city + ',' : ''} ${state ? state + ',' : ''} ${country}`
        this.ValidateAddress();
        this.errorMessage = '';
      } else {
        this.selectedAddress = null;
        this.$emit('change', null)
        this.errorMessage = 'Please select a valid address with all required fields.';
      }
    },
  },
};
</script>

<style scoped>
.error {
  color: red;
  margin-top: 10px;
}

.rectangle-list span:hover {
  background: #eee;
}

.rectangle-list .active:before {
  background: #4d1b7e !important;
  color: #fff;
}

.rectangle-list span:before {
  content: counter(li);
  counter-increment: li;
  position: absolute;
  left: -2.5em;
  top: 50%;
  margin-top: -1em;
  background: #eee;
  height: 2em;
  width: 2em;
  color: #0c0c0c;
  line-height: 2em;
  text-align: center;
  font-size: 14px;
}

.rectangle-list span:after {
  position: absolute;
  content: "";
  border: 0.5em solid transparent;
  left: -1em;
  top: 50%;
  margin-top: -0.5em;
  transition: all 0.3s ease-out;
}

.rectangle-list span:hover:after {
  left: -0.5em;
  border-left-color: #ccc;
}

.rectangle-list span.active:after {
  left: -0.5em;
  border-left-color: #4d1b7e;
}

.suggestion-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  /* Adjust max-height as needed */
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.custom-textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer !important;
}

/* Add your custom CSS styling here */
.popover-list {
  list-style-type: none;
  padding: 0;
  position: absolute;
  z-index: 11111111111;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  max-height: 250px;
  min-height: 50px;
  overflow-y: scroll;
}

.popover-item {
  padding: 8px;
}

.popover-item:hover {
  background-color: #f0f0f0;
}

.filterSearch {

  /* border: 1px solid #ccc; */
  border: none;
  border-radius: 4px;
  margin-bottom: 8px;
  margin: 0px;
}
</style>
<style>
.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial, sans-serif;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
  box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden
}

.pac-logo:after {
  content: "";
  padding: 1px 1px 1px 0;
  height: 18px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: right;
  display: block;
  background-image: url('');
  background-position: right;
  background-repeat: no-repeat;
  -webkit-background-size: 120px 14px;
  background-size: 120px 14px
}

.hdpi.pac-logo:after {
  background-image: url('')
}

.pac-item {
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 16px;
  color: #515151
}

.pac-item:hover {
  background-color: #fafafa
}

.pac-item-selected,
.pac-item-selected:hover {
  background-color: #ebf2fe
}

.pac-matched {
  font-weight: 700
}

.pac-item-query {
  font-size: 16px;
  padding-right: 3px;
  color: #000
}

.pac-icon {
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  -webkit-background-size: 34px 34px;
  background-size: 34px
}

.hdpi .pac-icon {
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png)
}

.pac-icon-search {
  background-position: -1px -1px
}

.pac-item-selected .pac-icon-search {
  background-position: -18px -1px
}

.pac-icon-marker {
  background-position: -1px -161px
}

.pac-item-selected .pac-icon-marker {
  background-position: -18px -161px
}

.pac-placeholder {
  color: gray
}


</style>